<template>
  <base-layout>
    <avatar />
    <articles />
    <button-list />
  </base-layout>
</template>

<script>
import Avatar from '@/components/Avatar'
import Articles from '@/components/Articles'
import ButtonList from '@/components/ButtonList'
import BaseLayout from '@/components/BaseLayout'
export default {
  name: 'Home',
  components: {
    Avatar,
    Articles,
    ButtonList,
    BaseLayout
  }
}
</script>
