<template>
  <div class="container">
    <hr class="divider" />
    <ul class="list">
      <li v-for="(article, index) in articlesList"
          :key="index">
        <a class="article-title"
           :href="article.url">
          {{ article.title }}
        </a>
        <span class="article-time">
          {{ article.date }}
        </span>
      </li>
    </ul>
    <a class="article-more-alabel"
       href="https://blog.r-ay.cn/">
      <span>更多博文 ></span>
    </a>
  </div>
</template>

<script>
export default {
  name: 'Articles',
  data () {
    return {
      articlesList: [
        {
          title: '【实战】 在程序中使用队列数据结构思想来规划多线程',
          url: 'https://blog.r-ay.cn/a/using-queue-to-process-msg-queue/',
          date: '27,02,2021'
        },
        {
          title: '【实战】使用「小米手环 5 NFC 版」模拟「校园一卡通」',
          url: 'https://blog.r-ay.cn/a/use-miband5nfc-get-schoolcard/',
          date: '22,07,2020'
        },
        {
          title: '【简谈】《拣爱》 —— “短小精悍”的爱情故事。',
          url: 'https://blog.r-ay.cn/a/thetaskoflovechoice/',
          date: '22,02,2020'
        }
      ]
    }
  }
}
</script>

<style lang="sass" scoped>
@import "src/assets/style/global"
.container
  padding: 0px 0 0px 0
  width: 80%
  max-width: 500px
  margin: 0 auto
  box-sizing: border-box
.divider
  color: rgb(white,0.3)
  border-style: solid
li,ul
  list-style: none
  padding: 0
.list li
  display: flex
  margin-bottom: 10px
  color: white
a
  text-decoration: none
.article-title
  flex: 1
  display: flex
  align-items: center
  color: white
.article-time
  color: hsla(0,0%,100%,.7)
.article-more-button
  margin-top: 16px
  display: flex
  color: white
  font-size: 18px
  background-color: rgba(white,.2)
  border-radius: 8px
  margin: 0 auto
  width: 128px
  height: 48px
  align-items: center
  justify-content: center
.article-more-alabel
  margin-top: 16px
  display: flex
  color: white
  font-size: 18px
</style>
