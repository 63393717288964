<template>
  <div class="container">
    <div class="avatar">
      <img src="/images/cb254102-aa64-4051-af5f-77a325f14ff7.png"
           alt="avatar">
    </div>
    <div class="name">
      <img height="144px"
           alt="Ray"
           src="/images/c18fcec0-94d4-4cb9-8db8-3284c88b1f23.svg"
           style="filter: invert(100%);" />
    </div>
  </div>
</template>

<script>
export default {
  name: 'Avatar'
}
</script>

<style lang="sass" scoped>
@import "src/assets/style/global"
$avatar-size: 100px
$ripple-color: white
$border-color: white
.container
  display: inline-block
  padding: 50px 0 20px 0
  box-sizing: border-box
  margin: 0 40px
  min-width: 305px
.name
  /*margin-top: 30px*/
  font-size: 2.5rem
  color: white
  float: left
  margin: -10px 0 0 50px
.avatar
  max-width: $avatar-size
  max-height: $avatar-size
  margin: 0 auto
  border-radius: 50%
  overflow: hidden
  border: $border-color 5px solid
  background-color: $border-color
  animation: at-ripple 2s linear infinite
  float: left
  @include flex-center
  img
    width: 100%
    height: 100%

@keyframes at-ripple
  0%
    box-shadow: 0 4px 10px rgba($ripple-color,0.1), 0 0 0 0 rgba($ripple-color,0), 0 0 0 5px rgba($ripple-color,0), 0 0 0 10px rgba($ripple-color,0)
  50%
    box-shadow: 0 4px 10px rgba($ripple-color,0.1), 0 0 0 0 rgba($ripple-color,0.2), 0 0 0 10px rgba($ripple-color,0.2), 0 0 0 25px rgba($ripple-color,0.2)
  100%
    box-shadow: 0 4px 10px rgba($ripple-color,0.0), 0 0 0 0 rgba($ripple-color,0.0), 0 0 0 18px rgba($ripple-color,0.0), 0 0 0 45px rgba($ripple-color,0.0)
@keyframes jump
  0%
    transform: translateY(0)
  50%
    transform: translateY(10px)
  100%
    transform: translateY(0)
</style>
